import Link from "next/link";
import Image from "next/image";

const features = [
    {
        name: "Type a Prompt",
        description:
            "Type in a description of your desired image. Enter as many details as you can to help the AI figure out what you want.",
    },
    {
        name: "Press Create",
        description:
            "Watch our AI engine create your vector graphic. Hang in there, it takes a few seconds.",
    },
    {
        name: "Download",
        description:
            "Download the image to your local disk. Your SVG file can be scaled to any size and still look amazing!",
    },
];

export default function GetStarted({ hideSubtitle = false }) {
    return (
        <div className="dark:bg-transparent bg-white py-16">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:text-center">
                    <h1 className="tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl font-serif text-2xl text-center font-light">
                        Get started with a few easy steps
                    </h1>
                    {!hideSubtitle && (
                        <p className="text-gray-600 mt-2 dark:text-gray-400 text-sm">
                            Create your own vector art with our AI engine
                        </p>
                    )}
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-12 lg:mt-20 lg:max-w-none">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                        {features.map((feature, i) => (
                            <div key={feature.name} className="flex flex-col">
                                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900 dark:text-gray-100">
                                    <div className="bg-primary text-white px-2 rounded-md">
                                        {i + 1}.
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600 dark:text-gray-300">
                                    <div className="mb-4 text-center lg:text-left">
                                        <Image
                                            className="inline rounded-md border overflow-hidden"
                                            alt={feature.name}
                                            width="320"
                                            height="172"
                                            src={`/get-started-${i + 1}.png`}
                                        />
                                    </div>
                                    <p className="flex-auto">
                                        {feature.description}
                                    </p>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
                <div className="mx-auto mt-16 max-w-2xl lg:max-w-none text-center">
                    <Link href="/create">
                        <button
                            type="button"
                            className="items-center inline-flex justify-center rounded-md px-4 py-3 text-base font-medium focus:outline-none sm:col-start-2 sm:text-sm  from-primary-dark border-0 to-secondary-dark bg-gradient-to-br text-white hover:bg-blue-700 hover:bg-gradient-to-bl"
                        >
                            <span className="text-lg mr-2 sparkle w-4">
                                <img
                                    width="16"
                                    height="16"
                                    alt="Create"
                                    src={"/sparkle-white.svg"}
                                />
                            </span>

                            <div>Get Started for Free</div>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
